export const getStorageValueToken = (key) => {
  return new Promise((resolve, reject) => {
    if (!window?.ma?.getStorage) {
      console.error("getStorage API is not available on this platform.");
      reject(new Error("getStorage API not available"));
      return;
    }

    window?.ma?.getStorage({
      key,
      success(res) {
        resolve(res.data); // Resolve the value if present
      },
      fail(err) {
        console.error(`Failed to retrieve key: ${key}`, err);
        reject(err); // Reject the error
      },
    });
  });
};

export async function getStorageValue(key) {
  return new Promise((resolve) => {
    if (window?.ma) {
      window?.ma?.getStorage({
        key,
        success(res) {
          console.log(typeof res, "typeof");
          const parsedData = typeof res === "string" ? JSON.parse(res) : res;
          resolve(parsedData);
          localStorage.setItem("b2bId", res);
        },
        fail(err) {
          console.error(`Failed to get ${key} from storage:`, err);
          resolve(null);
        },
      });
    } else {
      resolve(null); // Handle cases where `window.ma` is not available
    }
  });
}

export function getStorageValueJSON(key) {
  return new Promise((resolve) => {
    if (window?.ma) {
      window?.ma?.getStorage({
        key,
        success(res) {
          console.log(typeof res, "typeof");
          resolve(res);
        },
        fail(err) {
          console.error(`Failed to get ${key} from storage:`, err);
          resolve(null);
        },
      });
    } else {
      resolve(null); // Handle cases where `window.ma` is not available
    }
  });
}
